var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DeviceDetection } from '../../utils/deviceDetection';
import { useContext } from './context';
import NativeOption from './components/NativeOption';
import StyledOption from './components/StyledOption';
const isTouchDevice = DeviceDetection.isTouchDevice();
const Option = (_a) => {
    var props = __rest(_a, []);
    const { isNativeSelect } = useContext();
    const optionElement = isTouchDevice || isNativeSelect ? _jsx(NativeOption, Object.assign({}, props)) : _jsx(StyledOption, Object.assign({}, props));
    return optionElement;
};
export default Option;

import { createSlice } from '@reduxjs/toolkit';
import { AvailabilityPriceFilters } from '@constants';
const initialState = {
    selectedTimeIntervals: null,
    selectedPriceFilter: AvailabilityPriceFilters.all_prices,
    selectedBookingDate: '',
    selectedBookingTimeKitId: '',
    bookingMinutes: 0,
    selectedTeamsMeetingLink: false,
    manualMinutes: false
};
export const availabilitySlice = createSlice({
    name: 'availability',
    initialState,
    reducers: {
        setAvailabilityOptions: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
    }
});
export const { setAvailabilityOptions } = availabilitySlice.actions;
export default availabilitySlice.reducer;

import styled from 'styled-components';
import Button from '@frontend-components/components/Button';
import { themeColors } from '@frontend-components/css/themeColors';
const StyledProduct = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 40px;
`;
const StyledProductInput = styled.div `
  display: flex;
  width: 30%;
  `;
const StyledProductInfo = styled.div `
  width: 100%;
  padding: 0 0 15px 0;
  p {
    margin: 25px 0;
  }
`;
const StyledSelectWrapper = styled.div `
  display: flex;
  width: 100%;
  > *:nth-child(0n + 1) {
    margin-right: 12px;
  }
`;
const StyledSelectTitle = styled.div `
  margin: 10px 0 25px;
`;
const StyledProductCheck = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  div {
    label {
      padding: 0 0 25px 0;
    }
  }
`;
const StyledAddToCartBtnWrapper = styled.div `
  display: flex;
  justify-content: start;
  width: 100%;
 
  > *:nth-child(0n + 1) {
    margin-right: 10px;
  }
`;
const StyledAddToCartBtn = styled(Button) `
  width: 138px !important;
  background: ${themeColors.darkerTeal} !important;
  border: 1px solid ${themeColors.darkerTeal} !important;
`;
const StyledEditPriceButtonWrapper = styled.div `
  width: 55%;
  margin: 15px 0 10px;
`;
const StyledEditPriceInputWrapper = styled.div `
  display: flex;
  align-items: center;
  gap:10px;
`;
export { StyledProduct, StyledProductInfo, StyledProductInput, StyledProductCheck, StyledEditPriceButtonWrapper, StyledEditPriceInputWrapper, StyledAddToCartBtnWrapper, StyledAddToCartBtn, StyledSelectWrapper, StyledSelectTitle };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authHeaderName } from '@frontend-components/constants';
import { administrationApiUrl } from '@constants';
export const baseQueryWithAuth = ({ baseUrl }) => (args, api, extraOptions) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const addAuthHeader = (authToken) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield fetchBaseQuery({
            baseUrl,
            prepareHeaders: (headers) => {
                if (authToken != null && authToken.length > 0) {
                    headers.set(authHeaderName, authToken);
                }
                return headers;
            }
        })(args, api, extraOptions);
        return result;
    });
    const configToken = (_a = process.env.TOKEN) !== null && _a !== void 0 ? _a : '';
    let authToken = window.sessionStorage.getItem(authHeaderName);
    if (configToken.length > 0) {
        return addAuthHeader(configToken);
    }
    if (authToken == null) {
        try {
            const response = yield fetch(`${administrationApiUrl}/admin/api/backenduser/token`, { credentials: 'include' });
            if (response.ok && !response.redirected) {
                const responseToken = yield response.text();
                window.sessionStorage.setItem(authHeaderName, responseToken);
                authToken = responseToken;
            }
        }
        finally {
            // eslint-disable-next-line no-unsafe-finally
            return addAuthHeader(authToken);
        }
    }
    return addAuthHeader(authToken);
});

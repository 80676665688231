import styled from 'styled-components';
export const LabelWrapper = styled.div `
 display: flex; 
 gap: 5px;
 align-items: center
`;
export const ColorSquare = styled.div `
width: 10px;
height: 10px;
background-color: ${({ $color }) => $color}
`;

export const mapProductsToParams = (products) => products
    .map((product, idx) => `products[${idx}][product_type]=${product.productName}&products[${idx}][purchase_type]=${product.purchaseType}`)
    .join('&');
export const productsToParams = (products) => {
    const productsObj = products.reduce((acc, product, idx) => {
        acc[`products[${idx}][product_type]`] = product.productName;
        acc[`products[${idx}][purchase_type]`] = product.purchaseType;
        return acc;
    }, {});
    return productsObj;
};

import styled from 'styled-components';
import { themeColors } from '@frontend-components/css/themeColors';
const CreateProductWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;
const ProductHeadingWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0 30px 0;
`;
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themeColors.darkerTeal};
  width: 31px;
  height: 31px;
  margin-right: 10px;
  border-radius: 50%;
  svg {
    color: ${themeColors.white};
  }
`;
const StyledClosedDiv = styled.div `
  height: 50px;
  border-left: 1px solid;
  margin: 0 0 0 15px;
`;
export { CreateProductWrapper, ProductHeadingWrapper, IconWrapper, StyledClosedDiv };

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, memo } from 'react';
import Provider from './context/Provider';
import { DeviceDetection } from '../../utils/deviceDetection';
import { useContext } from './context';
import NativeSelect from './components/NativeSelect';
import StyledSelect from './components/StyledSelect';
const isTouchDevice = DeviceDetection.isTouchDevice();
const SelectResolver = (_a) => {
    var { isNative = false, onChange, value, isMultiSelect = false } = _a, props = __rest(_a, ["isNative", "onChange", "value", "isMultiSelect"]);
    const selectProps = Object.assign({ value, onChange, isMultiSelect }, props);
    const selectElement = (isTouchDevice || isNative) && !isMultiSelect ? _jsx(NativeSelect, Object.assign({}, selectProps)) : _jsx(StyledSelect, Object.assign({}, selectProps));
    const { selectedValue, setSelectedValue, setNativeSelect } = useContext();
    useEffect(() => {
        setNativeSelect(isNative);
    }, []);
    useEffect(() => {
        if (!value || value.length === 0)
            return;
        setSelectedValue(value);
    }, [value]);
    useEffect(() => {
        if (typeof onChange !== 'function')
            return;
        if (Array.isArray(selectedValue) && JSON.stringify(selectedValue) === JSON.stringify(value))
            return;
        if (!Array.isArray(selectedValue) && selectedValue === value)
            return;
        onChange(selectedValue);
    }, [selectedValue, value]);
    return selectElement;
};
const Select = (props) => (_jsx(Provider, { children: _jsx(SelectResolver, Object.assign({}, props)) }));
export default memo(Select);

import styled from 'styled-components';
import { themeColors } from '@frontend-components/css/themeColors';
const EndScreenWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: ${themeColors.green};
  > *:nth-child(0n + 1) {
    margin-right: 15px;
  }
`;
const EndScreenIconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themeColors.green};
  width: 31px;
  height: 31px;
  margin-right: 10px;
  border-radius: 50%;
  svg {
    color: ${themeColors.white};
  }
`;
export { EndScreenWrapper, EndScreenIconWrapper };

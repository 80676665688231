import { useEffect, useRef, useState } from 'react';
export function useToggleElementVisibility(timeoutMs = 200) {
    const [isVisible, setIsVisible] = useState(false);
    const timeout = useRef();
    useEffect(() => () => {
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
    }, []);
    return [isVisible, setVisibleTimeout];
    function setVisibleTimeout() {
        if (isVisible) {
            return;
        }
        setIsVisible(true);
        timeout.current = setTimeout(() => setIsVisible(false), timeoutMs);
    }
}

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    selectAdvisorTimekitId: '',
    selectPropertyType: '',
    selectManualDate: '',
    selectStartTime: ''
};
export const manualBookingSlice = createSlice({
    name: 'manualBooking',
    initialState,
    reducers: {
        setManualBookingOptions: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
    }
});
export const { setManualBookingOptions } = manualBookingSlice.actions;
export default manualBookingSlice.reducer;

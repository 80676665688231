var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback } from 'react';
import classnames from 'classnames';
import { useContext } from '../context';
import { extractTextFromReact } from '../../../utils/extractTextFromReact';
import InputWrapper from '../../../helperComponents/InputWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import styles from '../Select.module.scss';
const NativeSelect = (_a) => {
    var _b;
    var { label, name, disabled, placeholder, children, selectExtraProps } = _a, props = __rest(_a, ["label", "name", "disabled", "placeholder", "children", "selectExtraProps"]);
    const [isFocused, setFocused] = useState(false);
    const { selectedValue, setSelectedValue } = useContext();
    const areaLabelText = (_b = props['aria-label']) !== null && _b !== void 0 ? _b : extractTextFromReact(label !== null && label !== void 0 ? label : placeholder);
    const isWithValue = selectedValue.length > 0 && selectedValue !== placeholder;
    const isHidden = selectedValue.length === 0 && placeholder == null;
    const valueToShow = selectedValue !== placeholder ? selectedValue : '';
    const handleChange = useCallback((event) => {
        if (disabled) {
            return;
        }
        setSelectedValue(event.target.value);
    }, [disabled]);
    const placeholderElement = placeholder && placeholder.length > 0 ? (_jsx("option", { value: placeholder, children: placeholder })) : null;
    return (_jsx(InputWrapper, Object.assign({}, props, { name: name, value: valueToShow, isFocused: isFocused, label: label, placeholder: placeholder, rightIcon: _jsx(FontAwesomeIcon, { icon: faChevronDown }), disabled: disabled, children: _jsxs("select", Object.assign({}, selectExtraProps, { value: valueToShow, "aria-label": areaLabelText, name: name, onChange: handleChange, disabled: disabled, className: classnames(styles.select, {
                [styles.withValue]: isWithValue,
                [styles.hidden]: isHidden
            }, styles.nativeSelect), onFocus: () => setFocused(true), onBlur: () => setFocused(false), children: [placeholderElement, children] })) })));
};
export default NativeSelect;

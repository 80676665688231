import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@store/index';
import { handleBack } from '@store/slices/bookingWizardSlice';
import BookTime from '@components/BookTime';
import ManualBooking from '@components/ManualBooking';
import Checkbox from '@frontend-components/components/Checkbox';
import BookingMinutes from '@components/BookingMinutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/pro-regular-svg-icons';
import { CheckBoxWrapper, TempBackButtonWrapper, StyledBackWrapper, BookingFirstStepWrapper } from './CreateBooking.styles';
import { StyledWrapper } from '../../global.styles';
import { useFlag } from '@unleash/proxy-client-react';
import { useGetMinutesQuery } from '@store/services/booking';
var BookingType;
(function (BookingType) {
    BookingType[BookingType["NO_SELECTION"] = 0] = "NO_SELECTION";
    BookingType[BookingType["MANUAL_BOOKING"] = 1] = "MANUAL_BOOKING";
    BookingType[BookingType["BOOK_TIME"] = 2] = "BOOK_TIME";
})(BookingType || (BookingType = {}));
const bookingTypesMapper = {
    [BookingType.MANUAL_BOOKING]: ManualBooking,
    [BookingType.BOOK_TIME]: BookTime,
    [BookingType.NO_SELECTION]: () => null
};
const CreateBooking = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isEcommerceOn = useFlag('EcommerceON');
    const [selectedBookingType, setSelectedBookingType] = useState(BookingType.NO_SELECTION);
    const currentOrder = useAppSelector((state) => state.currentOrder);
    const { data: bookingMinutesData, isLoading: isBookingMinutesLoading, isFetching: isBookingMinutesFetching } = useGetMinutesQuery({
        products: currentOrder.items
    }, {
        skip: !isEcommerceOn
    });
    const handleBackClick = useCallback(() => {
        dispatch(handleBack());
        setSelectedBookingType(BookingType.NO_SELECTION);
    }, [dispatch]);
    const bookedAppointmentLabel = t('bookingTheAvailability.bookedAnAppointment');
    const chooseDateTimeLabel = t('bookingTheAvailability.chooseDateTimeForBooking');
    const BookingComponent = bookingTypesMapper[selectedBookingType];
    return (_jsx(StyledWrapper, { children: _jsxs(BookingFirstStepWrapper, { children: [isEcommerceOn && (_jsx(BookingMinutes, { editMinutesInputTestId: "basket-overview-edit-estimated-time", editMinutesBtnTestId: "basket-overview-estimated-time-action", bookingMinutesData: bookingMinutesData, isBookingMinutesFetching: isBookingMinutesFetching, isBookingMinutesLoading: isBookingMinutesLoading })), _jsxs(CheckBoxWrapper, { children: [_jsx(Checkbox, { label: bookedAppointmentLabel, name: bookedAppointmentLabel, handleOnChange: (isChecked) => setSelectedBookingType(isChecked ? BookingType.MANUAL_BOOKING : BookingType.BOOK_TIME), checked: selectedBookingType === BookingType.MANUAL_BOOKING, "data-cy": "create-booking-manualBooking-flow" }), _jsx(Checkbox, { label: chooseDateTimeLabel, name: chooseDateTimeLabel, handleOnChange: (isChecked) => setSelectedBookingType(isChecked ? BookingType.BOOK_TIME : BookingType.NO_SELECTION), checked: selectedBookingType === BookingType.BOOK_TIME, "data-cy": "create-booking-default-flow" })] }), selectedBookingType === BookingType.NO_SELECTION && (_jsxs(TempBackButtonWrapper, { children: [_jsx(FontAwesomeIcon, { icon: faArrowLeftLong }), _jsx(StyledBackWrapper, { onClick: handleBackClick, children: t('bookingTheAvailability.back') })] })), BookingComponent && _jsx(BookingComponent, {})] }) }));
};
export default CreateBooking;

import { getWordpressApiUrl } from '../utils/urls';
export * from './purchaseTypes';
export * from './products';
export * from './user';
export * from './advisorsTypes';
export * from './signatureTypes';
export * from './registrationTypes';
export const authCookieName = 'testaviva_app';
export const authHeaderName = 'X-AUTH-TOKEN';
export const recaptchaId = '6LeCm6gUAAAAAFPr1OwCh4Hh6bK1g9vy7XoLl97_';
export const recaptchaScriptUrl = `https://www.google.com/recaptcha/api.js?render=${recaptchaId}`;
export const applicationSpaRoutes = {
    onboardingIndex: '/onboarding',
    onboardingValues: '/onboarding/værdier',
    onboardingScore: '/onboarding/anbefaling',
    profileIndex: '/profile',
    profileEdit: '/profile/edit',
    profileBookings: '/profile/bookings',
    profilePayments: '/profile/payments',
    profileChangePassword: '/profile/change-password',
    resendActivationMail: '/gensend-aktiveringsmail',
    consentBorgerDK: '/samtykke-borger-dk',
    createDocument: '/opret-dokument',
    basket: '/basket',
    completePurchase: '/checkout-completed',
    receipt: '/receipt',
    login: '/login',
    userActivation: '/user-activation',
    notifications: '/notifications',
    myOverview: '/mit-overblik',
    myDocuments: '/my-documents',
    mitIdVerification: '/opret-dokument/adgang'
};
export const applicationRoutes = { dashboard: '/min-side' };
export var DocumentStatuses;
(function (DocumentStatuses) {
    DocumentStatuses["DRAFT"] = "draft";
    DocumentStatuses["WAITING_FOR_SIGNATURE"] = "waiting-for-signature";
    DocumentStatuses["WAITING_FOR_REGISTRATION"] = "waiting-for-registration";
    DocumentStatuses["WAITING_FOR_CONFIRMATION"] = "waiting-for-confirmation";
    DocumentStatuses["WAITING_FOR_TESTAVIVA_REGISTRATION"] = "waiting-for-testaviva-registration";
    DocumentStatuses["WAITING_FOR_MEETING"] = "waiting-for-meeting";
    DocumentStatuses["WAITING_FOR_PAYMENT"] = "waiting-for-payment";
    DocumentStatuses["UPDATE_REQUIRED"] = "update-required";
    DocumentStatuses["UPDATE_RECOMMENDED"] = "update-recommended";
    DocumentStatuses["SIGNED"] = "signed";
})(DocumentStatuses || (DocumentStatuses = {}));
export var IdentificationFilesTypes;
(function (IdentificationFilesTypes) {
    IdentificationFilesTypes["PASSPORT"] = "passport";
    IdentificationFilesTypes["DRIVERS_LICENSE"] = "drivers_license";
    IdentificationFilesTypes["OTHER"] = "other";
})(IdentificationFilesTypes || (IdentificationFilesTypes = {}));
export const wordPressApiUrl = getWordpressApiUrl(); // process.env.NODE_ENV === 'development' ? 'https://shared-frontend.development.testaviva.dk' : getServiceUrlFor(window.location.href, 'frontend')

import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useMemo } from 'react';
import Context from './Context';
import Consumer from './Consumer';
import { initValues } from './initValues';
const Provider = ({ children }) => {
    const [selectedValue, setSelectedValue] = useState(children.props.isMultiSelect ? [] : initValues.selectedValue);
    const [highlightedItemIndex, setHighlightedItemIndex] = useState(initValues.highlightedItemIndex);
    const [isNativeSelect, setNativeSelect] = useState(initValues.isNativeSelect);
    const [isMouseHighlightDisabled, setMouseHighlightDisabled] = useState(initValues.isMouseHighlightDisabled);
    const values = useMemo(() => ({
        selectedValue,
        isNativeSelect,
        highlightedItemIndex,
        isMouseHighlightDisabled,
        setSelectedValue: (value) => setSelectedValue(value),
        setNativeSelect: (value) => setNativeSelect(value),
        setHighlightedItemIndex: (index) => setHighlightedItemIndex(index),
        setMouseHighlightDisabled: (value) => setMouseHighlightDisabled(value)
    }), [
        selectedValue,
        isNativeSelect,
        highlightedItemIndex,
        isMouseHighlightDisabled
    ]);
    return (_jsx(Context.Provider, { value: values, children: _jsx(Consumer, { children: children }) }));
};
export default Provider;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import styles from './Spinner.module.scss';
const Spinner = (_a) => {
    var { size = 'medium', className, position = 'static', withOverlay = false } = _a, props = __rest(_a, ["size", "className", "position", "withOverlay"]);
    return (_jsx("div", Object.assign({}, props, { className: classnames(styles.wrapper, styles[position], className, { [styles.overlay]: withOverlay }), children: _jsx("div", { className: classnames(styles.spinner, styles[size]) }) })));
};
export default Spinner;

import { isRejectedWithValue } from '@reduxjs/toolkit';
import { sendNotification } from '../../utils/notifications';
const errorMiddleware = () => (next) => (action) => {
    var _a, _b, _c, _d;
    if (isRejectedWithValue(action)) {
        sendNotification({ type: 'error', data: { message: ((_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || 'Something went wrong', errors: ((_d = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.errors) || [] } });
    }
    return (next(action));
};
export default errorMiddleware;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { bookingApiUrl } from '@constants';
export const BOOKING_API_PRODUCT_KEY = 'productApi';
export const productApi = createApi({
    reducerPath: BOOKING_API_PRODUCT_KEY,
    baseQuery: fetchBaseQuery({ baseUrl: bookingApiUrl }),
    refetchOnFocus: true,
    endpoints: (builder) => ({
        getProducts: builder.query({
            query: () => ({
                url: '/api/products',
                method: 'GET'
            })
        })
    })
});
export const { useGetProductsQuery } = productApi;

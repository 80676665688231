import styled from 'styled-components';
import { themeColors } from '@frontend-components/css/themeColors';
const CreateBookingWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;
const BookingHeadingWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0 30px 0;
`;
const BookingFirstStepWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 30px 0;
`;
const CheckBoxWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > *:nth-child(0n + 1) {
    margin-right: 15px;
  }
`;
const TempBackButtonWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin: 20px 0 30px 0;
  > *:nth-child(0n + 1) {
    margin-right: 10px;
  }
  svg {
    color: ${themeColors.darkerTeal};
  }
`;
const StyledBackWrapper = styled.p `
  color: ${themeColors.darkerTeal};
`;
export { CreateBookingWrapper, BookingHeadingWrapper, BookingFirstStepWrapper, CheckBoxWrapper, TempBackButtonWrapper, StyledBackWrapper };

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    selectedProduct: 'none',
    selectedPurchaseType: 'none',
    isSecondUserSelected: false,
    secondUser: null
};
export const createProductSlice = createSlice({
    name: 'createProduct',
    initialState,
    reducers: {
        setCreateProductOptions: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
    }
});
export const { setCreateProductOptions } = createProductSlice.actions;
export default createProductSlice.reducer;

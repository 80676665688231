import { themeColors } from '@frontend-components/css/themeColors';
import styled from 'styled-components';
const EmptyStepIconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themeColors.white};
  border: 1px solid ${themeColors.darkerTeal};
  width: 31px;
  height: 31px;
  margin-right: 10px;
  border-radius: 50%;
  svg {
    color: ${themeColors.darkerTeal};
  }
`;
export default EmptyStepIconWrapper;

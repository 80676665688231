export class DeviceDetection {
    static isTouchDevice() {
        return matchMedia('(hover: none)').matches;
    }
    static isMacintosh() {
        return !!navigator.userAgent.match(/Mac/i);
    }
    static isBot() {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
    }
}

export var InputWrapperSizes;
(function (InputWrapperSizes) {
    InputWrapperSizes["small"] = "small";
    InputWrapperSizes["medium"] = "medium";
    InputWrapperSizes["multiline"] = "multiline";
})(InputWrapperSizes || (InputWrapperSizes = {}));
export var InputWrapperKinds;
(function (InputWrapperKinds) {
    InputWrapperKinds["bordered"] = "bordered";
    InputWrapperKinds["underline"] = "underline";
})(InputWrapperKinds || (InputWrapperKinds = {}));

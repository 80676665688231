import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    selectedAdvisorId: null,
    selectedAdvisorType: null
};
export const advisorIdSlice = createSlice({
    name: 'advisorId',
    initialState,
    reducers: {
        setAdvisorOptions: (state, action) => (Object.assign(Object.assign({}, state), action.payload))
    }
});
export const { setAdvisorOptions } = advisorIdSlice.actions;
export default advisorIdSlice.reducer;

import styled, { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle `
  html {
    box-sizing: border-box;
  }
  * {
    font-family: inherit;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  svg {
    display: block;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
`;
const StyledWrapper = styled.div `
  display: flex;
  padding: 0 20px;
  margin: 0 0 0 15px;
  border-left: 1px solid;
  width: 100%;
`;
const SectionContent = styled.div `
  max-width: 1100px;
  padding: 0 20px;
  margin: auto;
`;
export { GlobalStyle, StyledWrapper, SectionContent };

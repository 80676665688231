import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    activeStep: 0,
    completed: []
};
const bookingWizardSlice = createSlice({
    name: 'bookingWizard',
    initialState,
    reducers: {
        handleNext(state) {
            // If the active step is the last step, do nothing
            if (state.activeStep === 3) {
                return;
            }
            state.activeStep += 1;
        },
        handleBack(state) {
            if (state.activeStep > 0) {
                state.activeStep -= 1;
            }
        },
        addCompleted(state, action) {
            state.completed.push(action.payload);
        },
        removeCompleted(state, action) {
            state.completed = state.completed.filter((step) => step !== action.payload);
        }
    }
});
export const { handleNext, handleBack, addCompleted, removeCompleted } = bookingWizardSlice.actions;
export default bookingWizardSlice.reducer;

import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable function-paren-newline */
import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { useContext } from '../context';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useThrottle } from '../../../hooks/useThrottle';
import Spinner from '../../Spinner';
import { SelectConstants } from '../Select.constants';
import styles from '../Select.module.scss';
const Dropdown = ({ inputWrapperRef, emptySearchMessage = SelectConstants.emptySearchMessage, maxHeight = SelectConstants.maxHeight, name, dropdownWidth, isLoading, children }) => {
    const [dropdownStyles, setDropdownStyles] = useState({
        opacity: 0,
        width: 0,
        maxHeight,
        top: 0,
        left: 0
    });
    const { selectedValue, highlightedItemIndex, isMouseHighlightDisabled } = useContext();
    const { height: windowHeight, width: windowWidth } = useWindowSize();
    const dropdownRef = useRef(null);
    const throttledWindowHeight = useThrottle(windowHeight, SelectConstants.resizeTimeoutMs);
    const throttledWindowWidth = useThrottle(windowWidth, SelectConstants.resizeTimeoutMs);
    useEffect(() => {
        if (throttledWindowHeight === 0 || throttledWindowWidth === 0)
            return;
        calculateDropdownPosition();
    }, [throttledWindowHeight, throttledWindowWidth, children]);
    useEffect(() => {
        if (dropdownStyles.opacity === 0)
            return;
        scrollToSelectedOption();
    }, [selectedValue, dropdownStyles]);
    useEffect(() => {
        if (highlightedItemIndex > -1 && isMouseHighlightDisabled) {
            scrollToHighlightedItem();
        }
    }, [highlightedItemIndex, isMouseHighlightDisabled]);
    let dropdownContent = emptySearchMessage;
    if (isLoading) {
        dropdownContent = _jsx("div", { className: styles.spinnerWrapper, children: _jsx(Spinner, { position: "center", size: "small" }) });
    }
    else if (children.length > 0) {
        dropdownContent = children;
    }
    return (createPortal(_jsx("div", { "data-cy": `dropdown-${name}`, ref: dropdownRef, className: cx(styles.dropdown, { [styles.empty]: children.length === 0 }), style: dropdownStyles, children: dropdownContent }), document.body));
    function calculateDropdownPosition() {
        var _a, _b;
        if (inputWrapperRef.current == null)
            return;
        const inputWrapperRect = inputWrapperRef.current.getBoundingClientRect();
        const width = dropdownWidth !== null && dropdownWidth !== void 0 ? dropdownWidth : inputWrapperRect.width;
        // eslint-disable-next-line no-nested-ternary
        const left = dropdownWidth == null ? inputWrapperRect.left : dropdownWidth > inputWrapperRect.width ? inputWrapperRect.left - ((dropdownWidth - inputWrapperRect.width) / 2) : inputWrapperRect.left;
        const windowScrollTop = window.scrollY;
        const dropdownOffsetTop = inputWrapperRect.top + inputWrapperRect.height + SelectConstants.dropdownGap + windowScrollTop;
        let height = maxHeight;
        let top = dropdownOffsetTop;
        if (dropdownOffsetTop - windowScrollTop + maxHeight > windowHeight) {
            if (inputWrapperRect.top >= maxHeight) {
                const dropdownRect = (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                const dropdownHeight = (_b = dropdownRect === null || dropdownRect === void 0 ? void 0 : dropdownRect.height) !== null && _b !== void 0 ? _b : maxHeight;
                top = inputWrapperRect.top - dropdownHeight + windowScrollTop - SelectConstants.dropdownGap;
            }
            else {
                height = windowHeight - dropdownOffsetTop - SelectConstants.dropdownGap;
                if (height < SelectConstants.minHeight) {
                    height = SelectConstants.minHeight;
                }
            }
        }
        setDropdownStyles({
            width,
            top,
            left,
            maxHeight: height,
            opacity: 1
        });
    }
    function scrollToSelectedOption() {
        var _a;
        const selectedOption = children.find((option) => option.props.value === selectedValue);
        if (selectedOption == null)
            return;
        const optionNode = document.getElementsByClassName(selectedOption.props.className)[0];
        if (optionNode == null)
            return;
        const optionRect = optionNode.getBoundingClientRect();
        let scrollTopPosition = 0;
        if (optionRect.height > dropdownStyles.maxHeight) {
            scrollTopPosition = optionRect.top - dropdownStyles.top;
        }
        else {
            scrollTopPosition = optionRect.top - dropdownStyles.top - (dropdownStyles.maxHeight / 2) + (optionRect.height / 2);
        }
        (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.scroll(0, scrollTopPosition);
    }
    function scrollToHighlightedItem() {
        var _a, _b, _c, _d, _e;
        const highlightedOption = (_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.childNodes[highlightedItemIndex];
        if (highlightedOption == null)
            return;
        const { bordersHeight } = SelectConstants;
        const numberOfScrolls = Math.ceil(highlightedOption.offsetTop / dropdownStyles.maxHeight);
        if (highlightedOption.offsetTop + (numberOfScrolls * bordersHeight) + highlightedOption.getBoundingClientRect().height > dropdownStyles.maxHeight * numberOfScrolls) {
            (_b = dropdownRef.current) === null || _b === void 0 ? void 0 : _b.scroll(0, highlightedOption.offsetTop);
        }
        else if (((_d = (_c = dropdownRef.current) === null || _c === void 0 ? void 0 : _c.scrollTop) !== null && _d !== void 0 ? _d : 0 + (numberOfScrolls * bordersHeight)) >= highlightedOption.offsetTop + (numberOfScrolls * bordersHeight)) {
            (_e = dropdownRef.current) === null || _e === void 0 ? void 0 : _e.scroll(0, highlightedOption.offsetTop);
        }
    }
};
export default Dropdown;

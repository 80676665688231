import { jsx as _jsx } from "react/jsx-runtime";
import { fa0, fa1, fa2, fa3, fa4, fa5, fa6, fa7, fa8, fa9 } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const numberIcons = [
    _jsx(FontAwesomeIcon, { icon: fa0 }),
    _jsx(FontAwesomeIcon, { icon: fa1 }),
    _jsx(FontAwesomeIcon, { icon: fa2 }),
    _jsx(FontAwesomeIcon, { icon: fa3 }),
    _jsx(FontAwesomeIcon, { icon: fa4 }),
    _jsx(FontAwesomeIcon, { icon: fa5 }),
    _jsx(FontAwesomeIcon, { icon: fa6 }),
    _jsx(FontAwesomeIcon, { icon: fa7 }),
    _jsx(FontAwesomeIcon, { icon: fa8 }),
    _jsx(FontAwesomeIcon, { icon: fa9 })
];
const NumberIcon = ({ number }) => {
    if (number < 0 || number > 9) {
        throw new Error('Number must be between 0 and 9');
    }
    return numberIcons[number];
};
export default NumberIcon;

/* eslint-disable */
import styled from 'styled-components';
import { themeColors } from '@frontend-components/css/themeColors';
import Button from '@frontend-components/components/Button';
const TheBookTimeWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const StyledBookWrapper = styled.div `
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  border: 1px solid ${themeColors.black};
  background-color: ${themeColors.white};
  border-radius: 5px;
  width: 803px;
`;
const StyledDayPickerWrapper = styled.div `
  margin: 20px 0 0;
`;
const StyledButtonWrapper = styled.div `
  display: flex;
  margin: 20px 0 0;
  justify-content: center;
`;
const StyledAdvisorAvailability = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 425px;
  margin-top: 50px;
`;
const StyledButton = styled(Button) `
  background-color: ${({ $active }) => ($active ? `${themeColors.whiteSmoke} !important` : '#fff !important')};
  height: 28px !important;
  color: ${({ $active }) => ($active ? `${themeColors.darkerTeal} !important` : `${themeColors.black} !important`)};
  border: none !important;
  padding: 0 10px !important;
`;
const StyledAvailableTimes = styled.div `
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;
  margin-top: 20px;
`;
const StyledAvailabilityCheck = styled.div `
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 10px;
  > *:nth-child(0n + 1) {
    margin-right: 70px;
  }
`;
const StyledAvailabilityWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 200px;
`;
const StyledAvailability = styled.div `
  display: flex;
  justify-content: center;
  background-color: ${({ advisorColor }) => advisorColor === 'student' ? themeColors.warmPink : advisorColor === 'external_consultant' ? themeColors.black : advisorColor === 'lawyer' ? themeColors.darkerTeal : themeColors.darkTeal};
  color: ${({ $selected }) => ($selected ? themeColors.yellow : themeColors.white)};
  padding: 15px;
  border-radius: 5px;
  margin: 0px 10px 20px 0px;
  flex: 0 0 calc(50% - 10px);

  > *:nth-child(0n + 1) {
    margin-right: 5px;
  }
`;
const StyledAvailabilityParagraph = styled.p `
  text-align: center;
  font-size: 16px;
  cursor: pointer;
`;
const StyledBookingButton = styled(Button) `
  color: ${themeColors.white};
  margin: 20px 0 0;
  width: 150px;
`;
const StyledBestTimesWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;
  margin: 15px 0 0;
`;
const StyledTeamsWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 0;
  > *:nth-child(0n + 1) {
    margin-bottom: 10px;
  }
`;
const StyledButtonsWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 723px;
  align-items: baseline;
  > *:nth-child(0n + 1) {
    margin-right: 15px;
  }
`;
const StyledBookingDetailsWrapper = styled.div `
  display: flex;
`;
const weekNumberStyles = {
    fontSize: '16px',
    padding: '20px',
    borderRadius: '0px',
    backgroundColor: themeColors.lightGrey
};
const SelectedSlotDetailsWrapper = styled.div `
  display: flex;
  width: 94%;
  padding: 20px 0;
`;
const StyledSelectedSlotDetails = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100px;
  justify-content: center;
  background: #fbfbfb;
  width: 100%;
  align-items: center;
`;
export { StyledBookWrapper, StyledDayPickerWrapper, StyledSelectedSlotDetails, StyledButton, StyledBookingDetailsWrapper, StyledButtonWrapper, weekNumberStyles, StyledAvailableTimes, StyledAvailabilityCheck, StyledAvailabilityWrapper, StyledAvailability, StyledAvailabilityParagraph, StyledAdvisorAvailability, StyledBookingButton, StyledBestTimesWrapper, StyledTeamsWrapper, SelectedSlotDetailsWrapper, StyledButtonsWrapper, TheBookTimeWrapper };

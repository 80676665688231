import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EndScreenWrapper, EndScreenIconWrapper } from './EndScreen.styles';
import { sendNotification } from '@utils';
const EndScreen = () => {
    const { t } = useTranslation();
    useEffect(() => {
        sendNotification({ event: 'bookingCreated', type: 'success', data: { message: '', errors: [] } });
    }, []);
    return (_jsxs(EndScreenWrapper, { "data-cy": "create-booking-end-screen", children: [_jsx(EndScreenIconWrapper, { children: _jsx(FontAwesomeIcon, { icon: faCheck }) }), _jsx("h4", { children: t('bookingTheAvailability.bookingAdded') })] }));
};
export default EndScreen;

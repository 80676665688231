import { createSlice } from '@reduxjs/toolkit';
const initialState = { payload: null };
export const userIdSlice = createSlice({
    name: 'userId',
    initialState,
    reducers: {
        setUserId: (state, action) => {
            state.payload = action.payload;
        }
    }
});
export const { setUserId } = userIdSlice.actions;
export default userIdSlice.reducer;

export const themeColors = {
    // Teal
    lighterTeal: '#E5EDEE',
    lightTeal: '#C4D5D6',
    normalTeal: '#669598',
    darkTeal: '#337276',
    darkerTeal: '#004f54',
    // Grey
    whiteGrey: '#fafafa',
    lightGrey: '#f6f6f6',
    blueGrey: '#D6DADA',
    grey: '#BBC1C2',
    darkGrey: '#474747',
    darkerGrey: '#3D3D3D',
    // Other
    white: '#ffffff',
    hintOfGreen: '#F6F7F7',
    warmPink: '#c14f6d',
    warmPinkRgb: '193 79 109',
    black: '#000000',
    // Yellow
    yellow: '#FDB80A',
    // sucessGreen
    green: '#219653',
    // whiteSmoke
    whiteSmoke: '#F5F5F5'
};

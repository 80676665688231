export var BookingStatus;
(function (BookingStatus) {
    BookingStatus[BookingStatus["Created"] = 1] = "Created";
    BookingStatus[BookingStatus["Completed"] = 2] = "Completed";
    BookingStatus[BookingStatus["Cancelled"] = 3] = "Cancelled";
    BookingStatus[BookingStatus["Deprecated"] = 4] = "Deprecated";
    BookingStatus[BookingStatus["Rebooked"] = 5] = "Rebooked";
    BookingStatus[BookingStatus["Winback"] = 6] = "Winback";
    BookingStatus[BookingStatus["Downgrade"] = 7] = "Downgrade";
})(BookingStatus || (BookingStatus = {}));

import styled from 'styled-components';
import { themeColors } from '@frontend-components/css/themeColors';
const StyledBasket = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 40px;
  min-height: 400px;
`;
const StyledBasketInfo = styled.h3 `
  width: 100%;
  margin: 0 0 15px 0;
  font-weight: 500;
`;
const StyledBasketItems = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${themeColors.darkerTeal};
  color: ${themeColors.white};
  padding: 0 50px 0 18px;
  border-bottom: 1px solid ${themeColors.lightGrey};
`;
const StyledProductBasket = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  svg {
    color: ${themeColors.white};
    padding: 0 0 0 10px;
  }
`;
const StyledPartnerDiscount = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 10px 50px 10px 18px;
  width: 100%;
  background-color: ${themeColors.normalTeal};
  color: ${themeColors.white};
`;
const StyledTotal = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 10px 50px 10px 18px;
  width: 100%;
  background-color: ${themeColors.darkerTeal};
  color: ${themeColors.white};
`;
const StyledInputWrapper = styled.div `
  width: 120px !important;
  display: flex;
`;
const StyledManaulDiscount = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
  > *:nth-child(0n + 2) {
    margin-left: 10px;
  }
`;
const StyledEstimatedTime = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0 10px 19px;
  background-color: ${themeColors.lighterTeal};
  color: ${themeColors.darkerTeal};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 70px;
  > *:nth-child(0n + 1) {
    margin-right: 5px;
  }
  svg {
    color: ${themeColors.darkerTeal};
    padding: 0 0 0 5px;
  }
`;
const TheEstimatedTime = styled.p `
  font-weight: 700;
  color: ${themeColors.darkerTeal};
`;
const StyledNextStep = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 0 0;
`;
const StyledTempInputWrapper = styled.div `
  width: 70px !important;
  display: flex;
`;
const StyledFixedDiscount = styled.div `
  display: flex;
  justify-content: space-between;
  padding: 10px 50px 10px 18px;
  width: 100%;
  background-color: ${themeColors.normalTeal};
  color: ${themeColors.white};
`;
const StyledItemDetailsWrapper = styled.div `
  display: flex;
  width: 100%;
  height: 25px;
`;
const StyledFinalItemPrice = styled.div `
  width: 50%;
  text-align:right;
  font-size:0.85rem;
`;
const StyledItemPurchaseType = styled.div `
  width: 50%;
  text-align:left;
  font-size:0.85rem;
`;
export { StyledBasket, StyledBasketInfo, StyledBasketItems, StyledItemDetailsWrapper, StyledItemPurchaseType, StyledFinalItemPrice, StyledProductBasket, StyledPartnerDiscount, StyledTotal, StyledManaulDiscount, StyledInputWrapper, StyledEstimatedTime, TheEstimatedTime, StyledNextStep, StyledTempInputWrapper, StyledFixedDiscount };

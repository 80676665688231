import { paymentApiUrl } from '@constants';
import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '@store/middleware/baseQueryWithAuth';
export const PAYMENT_API_REDUCER_KEY = 'paymentApi';
export const paymentApi = createApi({
    reducerPath: PAYMENT_API_REDUCER_KEY,
    baseQuery: retry(baseQueryWithAuth({ baseUrl: paymentApiUrl })),
    tagTypes: ['PAYMENT'],
    refetchOnFocus: true,
    endpoints: (builder) => ({
        getBasket: builder.query({
            query: ({ userId }) => ({
                url: `/api/basket?user=${userId}`,
                method: 'GET'
            }),
            providesTags: ['PAYMENT']
        }),
        postBasket: builder.mutation({
            query: ({ userId, partnerId, item, employee }) => ({
                url: `/api/basket?user=${userId}&partner=${partnerId}${employee === 0 ? '' : `&employee=${employee}`}`,
                method: 'POST',
                body: {
                    identifier: item.identifier,
                    product: item.product,
                    amount: item.amount,
                    purchase_type: item.purchase_type,
                    onboarded_with_id: item.onboarded_with_id,
                    force_add: true,
                    editable: false
                }
            }),
            invalidatesTags: ['PAYMENT']
        }),
        postMultipleItems: builder.mutation({
            query: ({ userId, partnerId, items, employee }) => ({
                url: `/api/basket/batch?user=${userId}&partner=${partnerId}${employee === 0 ? '' : `&employee=${employee}`}`,
                method: 'POST',
                body: items
            }),
            invalidatesTags: ['PAYMENT']
        }),
        deleteBasketItem: builder.mutation({
            query: ({ basketId, userId }) => ({
                url: `/api/basket/item/${basketId}?user=${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['PAYMENT']
        }),
        updateBasketDiscount: builder.mutation({
            query: ({ basketId, userId, discount }) => ({
                url: `/api/basket/${basketId}?user=${userId}`,
                method: 'PUT',
                body: {
                    discount
                }
            }),
            invalidatesTags: ['PAYMENT']
        }),
        deleteBasket: builder.mutation({
            query: ({ userId }) => ({
                url: `/api/basket?user=${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['PAYMENT']
        })
    })
});
export const { useGetBasketQuery, usePostBasketMutation, useDeleteBasketItemMutation, useUpdateBasketDiscountMutation, usePostMultipleItemsMutation, useDeleteBasketMutation } = paymentApi;

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    orderIdentifier: '',
    totalPrice: 0,
    items: []
};
const ecommerceSlice = createSlice({
    name: 'ecommerce',
    initialState,
    reducers: {
        setCurrentOrder: (_, action) => action.payload
    }
});
export const { setCurrentOrder } = ecommerceSlice.actions;
export default ecommerceSlice.reducer;

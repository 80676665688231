import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '@store/middleware/baseQueryWithAuth';
import { bookingApiUrl } from '@constants';
import { productsToParams } from './utils';
export const BOOKING_API_USERS_KEY = 'usersApi';
export const usersApi = createApi({
    reducerPath: BOOKING_API_USERS_KEY,
    baseQuery: baseQueryWithAuth({ baseUrl: bookingApiUrl }),
    refetchOnFocus: true,
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: ({ userId }) => ({
                url: `/api/bookings/user/${userId}`,
                method: 'GET'
            })
        }),
        getAdvisors: builder.query({
            query: ({ user_id, products, basket_id }) => {
                const productsParams = products && productsToParams(products);
                const basketParams = basket_id ? { basket_id } : {};
                return ({
                    url: '/api/bookings/advisors',
                    method: 'GET',
                    params: Object.assign(Object.assign({ user_id }, productsParams), basketParams)
                });
            }
        })
    })
});
export const { useGetUsersQuery, useLazyGetAdvisorsQuery, useGetAdvisorsQuery } = usersApi;

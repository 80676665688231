import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { setAvailabilityOptions } from '@store/slices/availabilitySlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@store/index';
import Input from '@frontend-components/components/Input';
import Spinner from '@frontend-components/components/Spinner';
import IconButton from '@frontend-components/components/IconButton';
import { faCheck, faPen } from '@fortawesome/pro-regular-svg-icons';
import { StyledEstimatedTime, StyledTempInputWrapper, TheEstimatedTime } from '@components/Basket/Basket.styles';
import { sendNotification } from '@utils';
const BookingMinutes = ({ editMinutesInputTestId, editMinutesBtnTestId, bookingMinutesData, isBookingMinutesFetching, isBookingMinutesLoading }) => {
    const [isEditingBookingMinutes, setIsEditingBookingMinutes] = useState(false);
    const [editBookingMinutes, setEditBookingMinutes] = useState(0);
    const [orginalBookingMinutes, setOrginalBookingMinutes] = useState(-1);
    const bookingMinutes = useAppSelector((state) => state.availability.bookingMinutes);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const maxBookingMinutes = bookingMinutesData === null || bookingMinutesData === void 0 ? void 0 : bookingMinutesData.data.booking_maximum_minutes;
    const handleToggleEditMinutes = () => {
        if (!isEditingBookingMinutes) {
            setIsEditingBookingMinutes(true);
            return;
        }
        if (maxBookingMinutes != null && editBookingMinutes > maxBookingMinutes) {
            sendNotification({
                type: 'info',
                data: {
                    message: t('bookingTheBasket.minutesOverLimit'),
                    errors: [t('bookingTheBasket.minutesOverLimit')]
                }
            });
            dispatch(setAvailabilityOptions({ bookingMinutes: maxBookingMinutes, manualMinutes: editBookingMinutes !== orginalBookingMinutes }));
            setIsEditingBookingMinutes(false);
            return;
        }
        dispatch(setAvailabilityOptions({ bookingMinutes: editBookingMinutes, manualMinutes: editBookingMinutes !== orginalBookingMinutes }));
        setIsEditingBookingMinutes(false);
    };
    useEffect(() => {
        if (bookingMinutesData === null || bookingMinutesData === void 0 ? void 0 : bookingMinutesData.data) {
            dispatch(setAvailabilityOptions({
                bookingMinutes: bookingMinutesData.data.minutes
            }));
            if (orginalBookingMinutes === -1) {
                setOrginalBookingMinutes(bookingMinutesData.data.minutes);
            }
        }
    }, [bookingMinutesData]);
    if (isBookingMinutesFetching || isBookingMinutesLoading)
        return _jsx(Spinner, { position: "center" });
    return (_jsxs(StyledEstimatedTime, { children: [_jsx(TheEstimatedTime, { children: t('bookingTheBasket.estimatedTime') }), isEditingBookingMinutes ? (_jsx(StyledTempInputWrapper, { children: _jsx(Input, { "data-cy": editMinutesInputTestId, type: "number", name: t('bookingTheBasket.editEstimatedTime'), onChange: (event) => setEditBookingMinutes(Number(event.target.value)) }) })) : (_jsxs("p", { "data-cy": "basket-overview-estimated-time", children: [bookingMinutes, ' ', "Min"] })), _jsx(IconButton, { "data-cy": editMinutesBtnTestId, onClick: handleToggleEditMinutes, icon: isEditingBookingMinutes ? faCheck : faPen, title: t('bookingTheBasket.editEstimatedTime') })] }));
};
export default BookingMinutes;

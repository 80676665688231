import { useState, useEffect, useRef, useCallback } from 'react';
export function useThrottle(value, timeoutMs) {
    const [state, setState] = useState(value);
    const timeout = useRef();
    const clear = useCallback(() => {
        if (timeout.current != null) {
            clearTimeout(timeout.current);
        }
    }, [timeout.current]);
    useEffect(() => {
        clear();
        timeout.current = setTimeout(() => setState(value), timeoutMs);
        return () => clear();
    }, [value]);
    return state;
}

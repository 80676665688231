import { jsx as _jsx } from "react/jsx-runtime";
import Ecommerce from '@components/Ecommerce';
import CreateBooking from '../../containers/CreateBooking/CreateBooking';
import CreateProduct from '../../containers/CreateProduct/CreateProduct';
import { useFlag } from '@unleash/proxy-client-react';
import EndScreen from '@components/EndScreen';
const useSteps = () => {
    const isEcommerceEnabled = useFlag('EcommerceON');
    return [
        {
            title: 'bookingTheProduct.createProduct',
            component: isEcommerceEnabled ? _jsx(Ecommerce, {}) : _jsx(CreateProduct, {})
        },
        {
            title: 'bookingTheAvailability.createBooking',
            component: _jsx(CreateBooking, {})
        },
        {
            title: 'bookingTheAvailability.bookingCompleted',
            component: _jsx(EndScreen, {})
        }
    ];
};
export default useSteps;

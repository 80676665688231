import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import ColorLabel from './components/ColorLabel';
import { Container } from './ColorLabels.styles';
const ColorLabels = (props) => {
    const { colorLabels } = props;
    const { t } = useTranslation();
    return (_jsx(Container, { children: colorLabels.map((colorLabel) => (_jsx(ColorLabel, { color: colorLabel.color, text: t(colorLabel.text) }, `${colorLabel.text}`))) }));
};
export default ColorLabels;

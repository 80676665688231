export const initValues = {
    selectedValue: '',
    isNativeSelect: true,
    highlightedItemIndex: -1,
    isMouseHighlightDisabled: false,
    setSelectedValue: (value) => value,
    setNativeSelect: (value) => value,
    setHighlightedItemIndex: (index) => index,
    setMouseHighlightDisabled: (value) => value
};

/**
 * Adds time to a date.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 *
 * @param date  Date to start with
 * @param units  Number of units of the given interval to add.
 */
export function dateAdd(date, interval, units) {
    const calculatedDate = new Date(date); // don't change original date
    const checkRollover = () => {
        if (calculatedDate.getDate() !== date.getDate())
            calculatedDate.setDate(0);
    };
    const MS_IN_HOUR = 3600000;
    const MS_IN_MIN = 60000;
    const MS_IN_SEC = 1000;
    switch (interval.toLowerCase()) {
        case 'year':
            calculatedDate.setFullYear(calculatedDate.getFullYear() + units);
            checkRollover();
            break;
        case 'quarter':
            calculatedDate.setMonth(calculatedDate.getMonth() + 3 * units);
            checkRollover();
            break;
        case 'month':
            calculatedDate.setMonth(calculatedDate.getMonth() + units);
            checkRollover();
            break;
        case 'week':
            calculatedDate.setDate(calculatedDate.getDate() + 7 * units);
            break;
        case 'day':
            calculatedDate.setDate(calculatedDate.getDate() + units);
            break;
        case 'hour':
            calculatedDate.setTime(calculatedDate.getTime() + units * MS_IN_HOUR);
            break;
        case 'minute':
            calculatedDate.setTime(calculatedDate.getTime() + units * MS_IN_MIN);
            break;
        case 'second':
            calculatedDate.setTime(calculatedDate.getTime() + units * MS_IN_SEC);
            break;
        default:
            break;
    }
    return calculatedDate;
}
export const hasRealEstateItems = (ecommerceProducts, products) => {
    var _a;
    const REAL_ESTATE_CATEGORY_ID = 2;
    return (_a = ecommerceProducts === null || ecommerceProducts === void 0 ? void 0 : ecommerceProducts.some((item) => {
        const productIdentifier = Object.prototype.hasOwnProperty.call(item, 'productName') ? item.productName : item.product;
        const basketProduct = products.find((product) => product.machineName === productIdentifier);
        return (basketProduct === null || basketProduct === void 0 ? void 0 : basketProduct.categoryId) === REAL_ESTATE_CATEGORY_ID;
    })) !== null && _a !== void 0 ? _a : false;
};
export const getIdentifier = (identifier) => {
    if (identifier.slice(0, 4) === 'user')
        return identifier;
    return `user:${identifier}`;
};

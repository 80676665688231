import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useThrottle } from '../../hooks/useThrottle';
import Message from '../../components/Message';
import styles from './InputWrapper.module.scss';
const animationTimeoutMs = 200;
const InputWrapper = ({ kind = 'bordered', inputType = 'input', inputSize = 'medium', label, fixedLabel = false, value = '', disabled, isError = false, errorMessage, message, placeholder, name, classNames, leftIcon, rightIcon, isFocused, children }) => {
    const [isPlaceholderVisible, setPlaceholderVisible] = useState(value.length > 0);
    const [isFullWidth, setFullWidth] = useState(false);
    const inputLabel = label !== null && label !== void 0 ? label : placeholder;
    const noAnimation = label != null && placeholder != null;
    useEffect(() => {
        if (noAnimation || fixedLabel || value.length > 0 || isFocused) {
            setPlaceholderVisible(false);
            setFullWidth(true);
        }
        else {
            setPlaceholderVisible(true);
            setFullWidth(false);
        }
    }, [value, fixedLabel, isFocused, noAnimation]);
    const leftIconElement = leftIcon != null ? _jsx("span", { className: styles.icon, children: leftIcon }) : null;
    const rightIconElement = rightIcon != null ? _jsx("span", { className: styles.icon, children: rightIcon }) : null;
    const errorMessageElement = errorMessage ? _jsx(Message, { className: styles.errorMessage, type: "error", children: errorMessage }) : null;
    const messageElement = message ? _jsx(Message, { className: styles.message, children: message }) : null;
    const throttledFullWidth = useThrottle(isFullWidth, animationTimeoutMs);
    return (_jsxs("div", { className: classnames(styles.wrapper, classNames === null || classNames === void 0 ? void 0 : classNames.wrapper), children: [_jsxs("div", { className: classnames(styles.inputWrapper, styles[inputSize], styles[inputType], styles[kind], {
                    [styles.focused]: isFocused,
                    [styles.disabled]: disabled,
                    [styles.error]: isError,
                    [styles.withLeftIcon]: leftIcon != null,
                    [styles.withRightIcon]: rightIcon != null
                }), children: [leftIconElement, children, rightIconElement, _jsxs("fieldset", { className: styles.fieldset, children: [_jsx("label", { className: classnames(styles.label, {
                                    [styles.centered]: isPlaceholderVisible,
                                    [styles.fullWidth]: throttledFullWidth
                                }), htmlFor: name, children: inputLabel }), _jsx("legend", { className: classnames(styles.legend, {
                                    [styles.visible]: !isPlaceholderVisible,
                                    [styles.smaller]: inputLabel != null && String(inputLabel).length > 7
                                }), children: inputLabel })] })] }), errorMessageElement, messageElement] }));
};
export default InputWrapper;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppSelector } from '@store/index';
import { StyledClosedDiv } from '../../containers/CreateProduct/CreateProduct.styles';
import StepHeading from './StepHeading/StepHeading';
import useSteps from './useSteps';
import EndScreen from '@components/EndScreen';
const Step = (_a) => {
    var { isOpen, step, isLastStep } = _a, otherProps = __rest(_a, ["isOpen", "step", "isLastStep"]);
    return (_jsxs(_Fragment, { children: [_jsx(StepHeading, Object.assign({ isOpen: isOpen }, otherProps)), isOpen ? step : !isLastStep && _jsx(StyledClosedDiv, {})] }));
};
const Stepper = () => {
    const { activeStep } = useAppSelector((state) => state.bookingWizard);
    const steps = useSteps();
    const isLastStepActive = activeStep === steps.length - 1;
    if (isLastStepActive)
        return _jsx(EndScreen, {});
    return steps.map((step, index) => (_jsx(Step, { isOpen: activeStep === index, isCompleted: activeStep > index, title: step.title, step: step.component, stepNo: index + 1, isLastStep: index === steps.length - 1 }, step.title)));
};
export default Stepper;

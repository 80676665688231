import { useEffect, useRef } from 'react';
export function useEventListener(name, f, target = window) {
    const ready = useRef(false);
    useEffect(() => {
        if (target == null || ready.current === true) {
            return;
        }
        ready.current = true;
        target.addEventListener(name, f);
        // eslint-disable-next-line consistent-return
        return () => target.removeEventListener(name, f);
    }, [target]);
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProductsQuery } from '@store/services/product';
import { useGetUsersQuery } from '@store/services/users';
import { useAppDispatch } from '@store/index';
import { setUserId } from '@store/slices/userIdSlice'; // TODO: Is not needed, just makes code more complecated and adds extra rerendering. Can be replaced with getting user id from the url
import { getUrlParam } from '@frontend-components/utils/urls/urls';
import Spinner from '@frontend-components/components/Spinner';
import Message from '@frontend-components/components/Message';
import { SectionContent } from './global.styles';
import Stepper from '@components/Stepper';
import { useDeleteBasketMutation, useGetBasketQuery } from '@store/services/payment';
import { useFlag, useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react';
const App = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { flagsReady } = useFlagsStatus();
    const userId = getUrlParam(window.location.href, 'user_id');
    const updateContext = useUnleashContext();
    const { isLoading: isProductLoading, isError: isProductError } = useGetProductsQuery();
    const isEcommerceEnabled = useFlag('EcommerceON');
    const [deleteBasket, { isUninitialized: isDeleteBasketUninitialized }] = useDeleteBasketMutation({ fixedCacheKey: 'delete-basket' });
    const { isLoading: isUsersLoading, isError: isUsersError, isUninitialized: isUserUninitialized } = useGetUsersQuery({ userId: Number(userId) }, { skip: userId == null });
    const { data: basketData, isLoading: isPaymentLoading, isError: isPaymentError, isSuccess: isPaymentSuccess } = useGetBasketQuery({ userId: Number(userId) }, { skip: userId == null || isEcommerceEnabled });
    useEffect(() => {
        if (userId != null
            && isPaymentSuccess
            && isDeleteBasketUninitialized
            && !isEcommerceEnabled)
            deleteBasket({ userId: Number(userId) });
    }, [isPaymentSuccess, basketData]);
    useEffect(() => {
        dispatch(setUserId({ userId: Number(userId) }));
        updateContext({ userId: userId !== null && userId !== void 0 ? userId : undefined });
    }, []);
    if (!flagsReady || isProductLoading || isUsersLoading || (!isEcommerceEnabled && isPaymentLoading)) {
        return _jsx(Spinner, { position: "screen-center" });
    }
    if (isProductError || isUsersError || (!isEcommerceEnabled && isPaymentError)) {
        return _jsx(Message, { type: "error", children: t('global.errors.genericError') });
    }
    return isUserUninitialized ? (_jsx(Message, { type: "error", children: t('global.errors.genericError') })) : (_jsx(SectionContent, { children: _jsx(Stepper, {}) }));
};
export default App;

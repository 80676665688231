import { TimeIntervals } from '@constants';
import { addDays, endOfMonth, getMonth, isBefore, startOfMonth, isSameDay, format, startOfToday, addMinutes } from 'date-fns';
export const isDateDisabled = (date, enabledDaysData, isEnabledDaysFetching) => {
    if (isEnabledDaysFetching)
        return true;
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const isDateBeforeToday = isBefore(date, todayDate);
    if (!enabledDaysData || isDateBeforeToday)
        return isDateBeforeToday;
    return !enabledDaysData.includes(format(date, 'yyyy-MM-dd'));
};
export const shouldModifierBeApplied = (date, modifierCondition, enabledDaysData) => {
    var _a;
    return ((_a = enabledDaysData.find((enabledDayData) => enabledDayData.date === format(date, 'yyyy-MM-dd'))) === null || _a === void 0 ? void 0 : _a.availability_rate) === modifierCondition;
};
export const areAllDaysDisabled = (date, enabledDays) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const daysInMonth = new Date(year, month, 0).getDate();
    for (let day = 1; day <= daysInMonth; day += 1) {
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        if (enabledDays.includes(formattedDate)) {
            return false;
        }
    }
    return true;
};
export const findFirstAvailableDay = (selectedMonth, enabledDays) => {
    const today = startOfToday();
    const thisMonth = getMonth(today);
    const startDay = getMonth(selectedMonth) === thisMonth ? today : startOfMonth(selectedMonth);
    const endMonth = endOfMonth(selectedMonth);
    for (let day = startDay; isBefore(day, endMonth) || isSameDay(day, endMonth); day = addDays(day, 1)) {
        if (!isDateDisabled(day, enabledDays)) {
            return day;
        }
    }
    return null;
};
export const getFormattedBookingDateTime = (selectedBookingDate, bookingMinutes) => {
    const initialDate = new Date(selectedBookingDate);
    const formattedDate = format(initialDate, 'dd/MM-yyyy, HH:mm');
    const endTime = addMinutes(initialDate, bookingMinutes);
    const formattedEndTime = format(endTime, 'HH:mm');
    return `${formattedDate} - ${formattedEndTime}`;
};
export const getTimeIntervalTextId = (selectedTimeInterval) => {
    switch (selectedTimeInterval) {
        case TimeIntervals.before_dinner:
            return 'bookingTheAvailability.beforeDinner';
        case TimeIntervals.after_dinner:
            return 'bookingTheAvailability.afterDinner';
        case TimeIntervals.evening:
            return 'bookingTheAvailability.evening';
        default:
            return 'bookingTheAvailability.allDay';
    }
};
export const isTimeSlotForSelectedDate = (timeSlotDate, selectedDate) => timeSlotDate.split(' ')[0] === format(selectedDate, 'yyyy-MM-dd');

import styled from 'styled-components';
const StyledAdvisors = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0 0;
`;
const StyledAdvisorsInfo = styled.div `
  display: flex;
  width: 100%;
  > *:nth-child(0n + 1) {
    margin-right: 12px;
  }
`;
const ChooseAdvisor = styled.p `
  font-size: 16px;
  margin-bottom: 20px;
`;
export { StyledAdvisors, StyledAdvisorsInfo, ChooseAdvisor };

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { BookingHeadingWrapper } from '../../../containers/CreateBooking/CreateBooking.styles';
import EmptyStepIconWrapper from '../EmptyStep/EmptyStep.styles';
import { IconWrapper } from '../../../containers/CreateProduct/CreateProduct.styles';
import NumberIcon from './NumberIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
const StepIcon = ({ stepNo, isCompleted, isOpen }) => {
    const ClosedIcon = isCompleted ? (_jsx(FontAwesomeIcon, { icon: faCheck })) : (_jsx(NumberIcon, { number: stepNo }));
    if (isOpen) {
        return (_jsx(IconWrapper, { "data-cy": `create-booking-step${stepNo}-icon`, children: _jsx(NumberIcon, { number: stepNo }) }));
    }
    return (_jsx(EmptyStepIconWrapper, { "data-cy": `create-booking-empty-step${stepNo}-icon`, children: ClosedIcon }));
};
const StepHeading = (props) => {
    const { title } = props, iconProps = __rest(props, ["title"]);
    const { t } = useTranslation();
    return (_jsxs(BookingHeadingWrapper, { children: [_jsx(StepIcon, Object.assign({}, iconProps)), _jsx("h4", { "data-cy": `create-booking-step${iconProps.stepNo}-header`, children: t(title) })] }));
};
export default StepHeading;

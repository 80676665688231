import styled from 'styled-components';
const StyledManualBooking = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
const StyledSelectAdvisor = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > *:nth-child(0n + 1) {
    margin-bottom: 10px;
  }
  > *:nth-child(0n + 2) {
    width: 250px;
  }
  margin: 0 0 30px 0;
`;
const StyledPropertyType = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 30px 0;
  > *:nth-child(0n + 1) {
    margin-bottom: 10px;
  }
  > *:nth-child(0n + 2) {
    width: 250px;
  }
`;
const StyledTimeStartWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 30px 0;
  > *:nth-child(0n + 1) {
    margin-bottom: 10px;
  }
  
`;
const StyledTimeStart = styled.div `
  display: flex;
  width: 250px;
  justify-content: flex-start;
  > *:nth-child(0n + 1) {
    margin-right: 10px;
  }
`;
const StyledTimeEnd = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > *:nth-child(0n + 2) {
    margin-top: 10px;
    width: 140px;
  }
`;
export { StyledManualBooking, StyledSelectAdvisor, StyledPropertyType, StyledTimeStartWrapper, StyledTimeStart, StyledTimeEnd };

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DayPicker as DayPickerComponent } from 'react-day-picker';
import { da } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';
import './DayPicker.scss';
const DayPicker = (_a) => {
    var { onDayClick } = _a, props = __rest(_a, ["onDayClick"]);
    return (_jsx(DayPickerComponent, Object.assign({}, props, { locale: da, onDayClick: handleClickDay })));
    function handleClickDay(date, event) {
        if (!event.disabled) {
            onDayClick(date);
        }
    }
};
export default DayPicker;
